<template>
  <div>
    <center-dialogs/>
    <CRow class="justify-content-center">

      <CCol sm="8" md="7" >
        <CCard accent-color="primary" >
          <CCardBody>
            <CRow>
              <CCol class="text-center">
                <label for="input-vjs" class="border-white">
                  <div class="avatar-site2">
                    <img :src="url" class="img-avatar" alt="" width="100%" >
                    <div class="image-upload" style="position: absolute; top: 0px; left: 0%; background-color: orange;">
                      <i class="icon-camera icons font-4xl d-block mt-4"></i>
                    </div>
                  </div>
                </label>
                <input id="input-vjs" type="file" accept="image/*" @change=uploadImage style="display: none;"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="7">
                <CInput
                    label="Name"
                    placeholder="name"
                    v-model="title"
                >
                  <template #prepend-content><CIcon name="cil-notes"/></template>
                </CInput>
              </CCol>
              <CCol>
                <CInput
                    label="Price"
                    type="Number"
                    placeholder="0.0"
                    v-model="priceInWen"
                >
                  <template #prepend-content><img  style="width: 1.3rem" src="img/token/nextart.png" /></template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CSelect label="Category"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         :options="category"/>
              </CCol>
            </CRow>
            <CRow >
              <CCol col="12">
                <h6> <CIcon name="cil-notes" class="mr-2"/> Decription</h6>
              </CCol>
              <CCol col="12">
                <quill-editor class="mb-3" :content="decription" />
              </CCol>
              <CCol col="12">
                <CInput
                    label="Exprice Market"
                    type="datetime-local"
                    horizontal
                    v-model="expiresAt"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol>
                <CButton :disabled="!this.uploadImageState"  @click="onMintNFT" :color="(!this.uploadImageState == true)?'secondary':'info'" class="w-100">Mint NFT</CButton>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol>
                <CButton :disabled="!isMint" @click="onApproveNFTTOMarket" :color="(!this.isMint == true)?'secondary':'info'" class=" w-100">Approve NFT TO SHOP</CButton>
              </CCol>
            </CRow>
<!--            <CRow class="mt-3">-->
<!--              <CCol>-->
<!--                <CButton :disabled="!isApptoveShopAccess"  color="warning" class=" w-100">Approve SHOP</CButton>-->
<!--              </CCol>-->
<!--            </CRow>-->
            <CRow class="mt-3">
              <CCol>
                <CButton @click="onCreateOrderToMarkets"  :disabled="!isCreateOrder"  :color="(!this.isCreateOrder == true)?'secondary':'info'" class=" w-100">Create TO MAKET</CButton>
              </CCol>
            </CRow>
          </CCardBody>

        </CCard>
      </CCol>
    </CRow>
<!--    <MetamaskConnect user-message="msg" @onComplete="onComplete"/>-->


  </div>
</template>

<script>

import {ABI, address} from "@/store/modules/Metamask/constants/escrowContract";
import Web3 from "web3";
  import {mapGetters} from 'vuex'
  import Vue from 'vue'
  import VueQuillEditor from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
import DialogConsistency from "@/project/views/pages/dialog/DialogConsistency";
import CenterDialogs from "@/containers/CenterDialogs";
import store from "@/store/store";
  Vue.use(VueQuillEditor)
export default {
  name: 'Profile',
  components: {CenterDialogs, DialogConsistency},
  data: function () {
    return {
      url:"img/upload/upload.png",
      category:[{
        label:"Photo",
        value:"Photo"
      },{
        label:"painting",
        value:"painting"
      },
      ],
      decription:"",
      uploadImageState:false,
      priceInWen:0,
      expiresAt:0,
      dateTime:0
    }
  },
  mounted () {

  },

  created () {
    // window.web3 = new Web3(web3.currentProvider);
//
//
//
//
//     let tokenAddress = "0x9f422aA35ABa73fb966EF5529CBD9013722B76A9";
//     let toAddress = "0xbABB593292F3Caeac61573D3488bB3A4AB1d445a";
//     let fromAddress = "0xe7c5E227cF0C1f95A8e2F63911BCaf66bD916708";
// // Use BigNumber
// //     let decimals = web3.utils.toBN(18);
// //     let amount = web3.utils.toBN(100);
//     let minABI = [
//       // transfer
//       {
//         "constant": false,
//         "inputs": [
//           {
//             "name": "_to",
//             "type": "address"
//           },
//           {
//             "name": "_value",
//             "type": "uint256"
//           }
//         ],
//         "name": "transfer",
//         "outputs": [
//           {
//             "name": "",
//             "type": "bool"
//           }
//         ],
//         "type": "function"
//       }
//     ];
// // Get ERC20 Token contract instance
//
//     let contract = new web3.eth.Contract(ABI, address);
//     console.log("contract",contract)
// // calculate ERC20 token amount
//
//     let decimals = web3.utils.toBN(18);
//     let amount = web3.utils.toBN(500);
//     let value = amount.mul(web3.utils.toBN(10).pow(decimals));
//     console.log(value)
// // // call transfer function
//     contract.methods.transfer(toAddress, value).send({from: fromAddress})
//         .on('transactionHash', function(hash){
//           console.log(hash);
//         });

  },

  beforeDestroy () {

  },

  methods: {
    onComplete(data){
      console.log('data:', data);
    },
    onView(){
      alert(1)
    },

    uploadImage(e){
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
        this.url = e.target.result;
        this.uploadImageState = true;
      };
    },

    onMintNFT(){

      var obj = {};
      obj.address = this.$store.getters['metamask/onGetMetaMaskAddress']
      obj.url = "https://finnext.io/test.json"
      this.$store.dispatch('erc721/onMintNft',obj)
    },


    onApproveNFTTOMarket(){
      // on Approve NFT access Market
      var obj = {};
      obj.address = this.$store.getters['erc721/onGetMetaMaskAddress']
      obj.tokenId = this.$store.getters['erc721/objTokenId']
      this.$store.dispatch('erc721/onApprove',obj)

    },


    onCreateOrderToMarkets(){
      var obj = {};
      obj.nftAddress = "0xE185A43bCc5006002fE0663e5c41989F17216C41"
      obj.tokenId = this.$store.getters['erc721/objTokenId']
      obj.priceInWen= this.priceInWen
      obj.expiresAt = this.dateTime

      console.log(obj)

      this.$store.dispatch('eRCMarketPlace/onCreateOrderToMarkets',obj)

    }

  },

  computed:{
    ...mapGetters({
      isMint:"erc721/objIsMint",
      isApptoveShopAccess:"erc721/objIsApptoveShopAccess",
      isCreateOrder:"eRCMarketPlace/objIsCreateOrder"
    })
  },

  watch: {
    expiresAt(value){
      // var date = new Date(value).getTime()/1000
      this.dateTime = new Date(value).getTime()/1000;
    }
  }
}
</script>
